export const CURRENCY_PLN = 'PLN'
export const COOKIE_EXPIRATION_DAYS = 30
export const DATE_FORMAT = 'dd.MM.yyyy' // date-fns format
export const DATE_FORMAT_ARIA_LABEL = 'ddd D MMM yyyy' // moment.js format
export const DATE_FORMAT_SHORT_YEAR = 'dd.MM.yy' // date-fns format
export const DATE_HOUR_FORMAT = 'dd.MM.yyyy HH:mm' // date-fns format
export const HOUR_FORMAT = 'HH:mm' // date-fns format
export const DATE_PLACEHOLDER = 'dd.mm.yyyy'
export const DEBOUNCE_GET_DELAY_DEFAULT = 500 // [ms]
export const DEBOUNCE_POST_DELAY_DEFAULT = 2000 // [ms]
export const NOTIFIER_CLOSE_TIME = 4000 // [ms]
export const ITEMS_PER_PAGE = 10
export const USER_TABLE_ITEMS_PER_PAGE = 25
export const ROLES_TABLE_ITEMS_PER_PAGE = 25
export const INPUT_LIST_LIMIT = 10
export const REGEX_NUMBERS_COMMA_OR_SPACE_SEPARATED = /^\d+(?:[\d+,\s])*$/
export const MAX_SAFE_GQL_INTEGER = 2_147_483_647 // (2**31) - 1
export const CREATIONS_MAX_FILES = 10
export const CREATIONS_MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024 // 1 GB
export const CREATIONS_MAX_FILE_DIMENSIONS = '7680x7680'
export const RESERVATION_DEADLINE_HOUR_IN_POLAND = 18
export const MAX_DATE_TIMESTAMP = 8640000000000000
export const PRIORITIES_SETS = [[3], [3, 4], [3, 4, 5], [4], [4, 5], [5]]
export const PRIORITIES = [3, 4, 5, 6, 7]
export const CREATION_DURATIONS = [5, 10, 15, 20, 25, 30]
export const MEDIA_DURATIONS = [10, 15, 20, 25, 30]
export const CREATION_DETAILS_CHANGE_DEBOUNCE_TIME = 500
export const DEFAULT_CREATION_DURATION = 10
export const AGENCIES_LIMIT = 20
export const ADDITIONAL_BEST_EFFORT_PRIORITY = 4
export const ALCOHOL_THEME_DEFAULT = false
